// src/App.js

import React, { useState, useEffect, Suspense, lazy  } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Container,
  Paper,
  Button,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  Link as MuiLink,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import InfoIcon from '@mui/icons-material/Info';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AccountBalanceWallet,
  TrendingUp,
  Whatshot as FireIcon,
  MonetizationOn,
  Security,
  Loop,
  Star,
  EmojiEvents,
  ContentCopy as CopyIcon,
} from '@mui/icons-material';

import {
  ConnectionProvider,
  WalletProvider,
  useWallet,
} from '@solana/wallet-adapter-react';
import {
  WalletModalProvider,
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';

import '@solana/wallet-adapter-react-ui/styles.css';
import Header from './components/Header'; // Adjust the path if necessary
import ClaimHistory from './components/ClaimHistory'; // Newly added component
import Airdrop from './components/Airdrop'; // Newly added component

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6200ea', // Purple color
    },
    secondary: {
      main: '#03dac6', // Teal color
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
    },
    body2: {
      fontSize: '1rem',
    },
  },
});

function formatNumber(number, fractionDigits = 6) {
  if (number === null || number === undefined) return '-';
  return parseFloat(number).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: fractionDigits,
  });
}

// Function to shorten long addresses
function shortenAddress(address) {
  if (!address) return '';
  return address.slice(0, 4) + '...' + address.slice(-4);
}

// Define the BurnedTokensProjection component inside App.js
function BurnedTokensProjection({ walletAddress, secondLawRequirement }) {
  const [burnedHistory, setBurnedHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [projectionDays, setProjectionDays] = useState(null);
  const [averageBurnRate, setAverageBurnRate] = useState(0);

  const perPage = 30; // Fetch last 30 days

  const fetchBurnedHistory = async () => {
    try {
      const API_BASE_URL =
        process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

      const response = await fetch(
        `${API_BASE_URL}/api/miner/${encodeURIComponent(
          walletAddress
        )}/burned_tokens_history?page=1&per_page=${perPage}`,
        {
          credentials: 'include', // Include credentials if using sessions
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setBurnedHistory(data.burned_tokens_history);
        calculateProjection(data.burned_tokens_history);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch burned tokens history');
      }
    } catch (err) {
      setError('Error fetching burned tokens history: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateProjection = (history) => {
    if (history.length === 0) {
      setProjectionDays(null);
      setAverageBurnRate(0);
      return;
    }

    // Calculate total burned and number of days
    const totalBurned = history.reduce((acc, day) => acc + day.total_burned, 0);
    const numberOfDays = history.length;

    // Calculate average daily burn rate
    const avgBurnRate = totalBurned / numberOfDays;
    setAverageBurnRate(avgBurnRate); // Store in state

    if (avgBurnRate === 0) {
      setProjectionDays(null);
      return;
    }

    // Calculate remaining amount to reach 0
    const remaining = secondLawRequirement;

    // Calculate required days
    const requiredDays = Math.ceil(remaining / avgBurnRate);

    setProjectionDays(requiredDays);
  };

  useEffect(() => {
    if (walletAddress && secondLawRequirement > 0) {
      fetchBurnedHistory();
    } else {
      setIsLoading(false);
    }
  }, [walletAddress, secondLawRequirement]);

  // Prepare data for the chart using native Date methods
  const chartData = burnedHistory
    .map((day) => ({
      date: new Date(day.date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      total_burned: day.total_burned,
    }))
    .reverse(); // To display oldest first

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        0-Trust Policy Projection
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      ) : (
        <>
          {burnedHistory.length > 0 ? (
            <>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <RechartsTooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="total_burned"
                    name="Tokens Burned"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
              {projectionDays !== null ? (
                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                  At the current average burn rate of{' '}
                  {averageBurnRate.toFixed(2)} tokens/day, it will take
                  approximately {projectionDays} day(s) to reduce your 0-Trust
                  Policy to zero.
                </Typography>
              ) : (
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                  Unable to calculate projection due to zero burn rate or
                  insufficient data.
                </Typography>
              )}
            </>
          ) : (
            <Typography variant="body2" sx={{ mt: 2 }}>
              No burned tokens history available to calculate projection.
            </Typography>
          )}
        </>
      )}
    </Paper>
  );
}
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function MinerData({ walletAddress }) {
  const [minerData, setMinerData] = useState(null);
  const [totalMiners, setTotalMiners] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [noMinerData, setNoMinerData] = useState(false);
  const [totals, setTotals] = useState(null);
  const [claimEnabled, setClaimEnabled] = useState(false);
  const [withdrawEnabled, setWithdrawEnabled] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const wallet = useWallet();
  const fetchData = async () => {
    try {
      const API_BASE_URL =
        process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

      // Fetch the claim status
      const claimStatusResponse = await fetch(
        `${API_BASE_URL}/api/claim_status`,
        {
          credentials: 'include', // Include credentials if using sessions
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (claimStatusResponse.ok) {
        const claimStatusData = await claimStatusResponse.json();
        setClaimEnabled(claimStatusData.claim_enabled);
      } else {
        setError('Failed to fetch claim status');
      }

      // Fetch the miner data
      const minerResponse = await fetch(
        `${API_BASE_URL}/api/miner/${encodeURIComponent(walletAddress)}`,
        {
          credentials: 'include', // Include credentials if using sessions
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (minerResponse.ok) {
        const minerDataResponse = await minerResponse.json();
        if (minerDataResponse.minerData) {
          setMinerData(minerDataResponse.minerData);
          setNoMinerData(false);
        } else {
          setNoMinerData(true);
          setIsLoading(false);
          return;
        }
      } else {
        setError('Failed to fetch miner data');
        setIsLoading(false);
        return;
      }

      // Fetch the miners statistics
      const minersStatsResponse = await fetch(
        `${API_BASE_URL}/api/miners/stats`,
        {
          credentials: 'include', // Include credentials if using sessions
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (minersStatsResponse.ok) {
        const minersStatsData = await minersStatsResponse.json();
        setTotalMiners(minersStatsData.total_miners);
      } else {
        setError('Failed to fetch miners statistics');
      }

      // Fetch the miner totals
      const totalsResponse = await fetch(
        `${API_BASE_URL}/api/miner/${encodeURIComponent(walletAddress)}/totals`,
        {
          credentials: 'include', // Include credentials if using sessions
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (totalsResponse.ok) {
        const totalsData = await totalsResponse.json();
        setTotals(totalsData.totals);
      } else {
        setError('Failed to fetch miner totals');
      }
    } catch (err) {
      setError('Error fetching data: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      minerData &&
      parseFloat(minerData.second_law_requirement) === 0 &&
      parseFloat(minerData.token_balance) > 0
    ) {
      setWithdrawEnabled(true);
    } else {
      setWithdrawEnabled(false);
    }
  }, [minerData]);

  useEffect(() => {
    if (walletAddress) {
      fetchData();
    }
  }, [walletAddress, wallet.connected]);


const handleAuthenticate = async () => {
  if (!wallet.connected || !wallet.publicKey || !wallet.signMessage) {
    alert('Please connect your wallet and ensure it supports message signing.');
    return false;
  }

  try {
    // Fetch nonce from the server
    const responseNonce = await fetch(`${API_BASE_URL}/api/nonce`, {
      credentials: 'include',
    });
    const dataNonce = await responseNonce.json();
    const nonce = dataNonce.nonce;

    // Create the message to sign
    const message = `Authentication nonce: ${nonce}`;
    const encodedMessage = new TextEncoder().encode(message);

    // Sign the message with the wallet
    const signature = await wallet.signMessage(encodedMessage);

    // Send the public key, signature, and nonce to the server for verification
    const responseLogin = await fetch(`${API_BASE_URL}/api/login`, { // Use correct URL
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        publicKey: wallet.publicKey.toBase58(),
        signature: Array.from(signature),
        nonce: nonce,
      }),
    });

    if (responseLogin.ok) {
      setIsAuthenticated(true);
      setError(''); // Clear any previous errors
      return true;
    } else {
      const errorData = await responseLogin.json();
      setError(errorData.message || 'Authentication failed');
      return false;
    }
  } catch (err) {
    setError('Authentication error: ' + err.message);
    return false;
  }
};

const handleClaim = async () => {
  if (isClaiming) return;
  setIsClaiming(true);
  setError('');

  // Check if the user is authenticated
  if (!isAuthenticated) {
    // Prompt authentication
    const authSuccess = await handleAuthenticate();
    if (!authSuccess) {
      setIsClaiming(false);
      return; // Stop if authentication fails
    }
  }

  try {
    const response = await fetch('/api/claim_tokens', { // Use relative URL
      method: 'POST',
      credentials: 'include', // Ensure credentials are included
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        miner_id: minerData.miner_id,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      alert('Tokens claimed successfully!');
      fetchData();
    } else {
      const errorData = await response.json();
      setError(errorData.message || 'Failed to claim tokens');
    }
  } catch (err) {
    setError('Error claiming tokens: ' + err.message);
  } finally {
    setIsClaiming(false);
  }
};

const handleWithdraw = async () => {
  if (isWithdrawing) return; // Prevent multiple withdrawals at the same time
  setIsWithdrawing(true);
  setError('');

  // Check if the user is authenticated
  if (!isAuthenticated) {
    // Prompt authentication
    const authSuccess = await handleAuthenticate();
    if (!authSuccess) {
      setIsWithdrawing(false);
      return; // Stop if authentication fails
    }
  }

  try {
    // Use relative URL assuming proxy is set up, else use `${API_BASE_URL}/api/withdraw_tokens`
    const response = await fetch('/api/withdraw_tokens', {
      method: 'POST',
      credentials: 'include', // Ensure credentials are included
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        miner_id: minerData.miner_id,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      alert('Tokens withdrawn successfully!');
      
      // Optionally, provide the transaction signature
      if (data.transactionSignature) {
        // Create a link to Solana Explorer (adjust network as needed)
        const explorerLink = `https://explorer.solana.com/tx/${data.transactionSignature}?cluster=devnet`;
        alert(`Tokens withdrawn successfully!\nView Transaction: ${explorerLink}`);
      }

      fetchData(); // Refresh data to reflect updated balances
    } else {
      const errorData = await response.json();
      setError(errorData.message || 'Failed to withdraw tokens');
    }
  } catch (err) {
    setError('Error withdrawing tokens: ' + err.message);
  } finally {
    setIsWithdrawing(false);
  }
};
  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 4 }}>
        {error}
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (noMinerData) {
    return (
      <Alert severity="info" sx={{ mt: 4 }}>
        Oh, look at you! Just starting out with Useless Brick? Be patient and
        wait for your turn.
      </Alert>
    );
  }

  let days = 1;

  if (minerData.last_claim_date) {
    const lastClaimDate = new Date(minerData.last_claim_date);
    const now = new Date();
    const diffTime = now - lastClaimDate;
    days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  } else if (minerData.start_date) {
    const startDate = new Date(minerData.start_date);
    const now = new Date();
    const diffTime = now - startDate;
    days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  const displayScore = minerData.score
    ? (minerData.score / 1000).toFixed(3)
    : '0';

  const claimButtonEnabled =
    claimEnabled && parseFloat(minerData.amount_accumulated) > 0;

  const tokenBalance = parseFloat(minerData.token_balance) || 0;

  return (
    <>
      <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Miner Data
        </Typography>
        <Grid container spacing={2}>
          {/* Miner ID */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                avatar={<AccountBalanceWallet color="primary" />}
                title="Miner ID"
                titleTypographyProps={{ variant: 'subtitle1' }}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  {minerData.miner_id}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Address */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                avatar={<AccountBalanceWallet color="primary" />}
                action={
                  <Tooltip title="Copy Address" arrow>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(minerData.address);
                        alert('Address copied to clipboard!');
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                }
                title="Address"
                titleTypographyProps={{ variant: 'subtitle1' }}
              />
              <CardContent>
                <Tooltip title={minerData.address} arrow>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {minerData.address}
                  </Typography>
                </Tooltip>
              </CardContent>
            </Card>
          </Grid>
          {/* Rank */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                avatar={<EmojiEvents color="primary" />}
                title="Rank"
                titleTypographyProps={{ variant: 'subtitle1' }}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  {minerData.rank && totalMiners
                    ? `${formatNumber(minerData.rank)} of ${formatNumber(
                        totalMiners
                      )}`
                    : 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Latest Round Won */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                avatar={<EmojiEvents color="primary" />}
                title="Latest Round Won"
                titleTypographyProps={{ variant: 'subtitle1' }}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  {minerData.latest_receipt
                    ? new Date(minerData.latest_receipt).toLocaleString()
                    : 'No rounds won yet'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Token Balance and Withdraw Button */}
<Grid item xs={12} sm={6} md={4}>
  <Card>
    <CardHeader
      avatar={<MonetizationOn color="primary" />}
      action={
        tokenBalance > 0 && (
          <Tooltip
            title={
              withdrawEnabled
                ? 'Withdraw your tokens'
                : '0-Trust Policy must be zero to withdraw'
            }
            arrow
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                disabled={!withdrawEnabled || isWithdrawing}
                onClick={handleWithdraw}
                size="small"
              >
                {isWithdrawing ? (
                  <CircularProgress size={24} />
                ) : (
                  'Withdraw'
                )}
              </Button>
            </span>
          </Tooltip>
        )
      }
      title="Token Balance"
      titleTypographyProps={{ variant: 'subtitle1' }}
    />
    <CardContent>
      <Typography variant="body2" color="textSecondary">
        {formatNumber(minerData.token_balance)}
      </Typography>
    </CardContent>
  </Card>
</Grid>
          {/* Accumulated Amount and Claim Button */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                avatar={<MonetizationOn color="primary" />}
                action={
                  <Tooltip
                    title={
                      claimEnabled
                        ? 'Claim your tokens'
                        : 'Claim currently disabled'
                    }
                    arrow
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!claimButtonEnabled || isClaiming}
                        onClick={handleClaim}
                        size="small"
                      >
                        {isClaiming ? (
                          <CircularProgress size={24} />
                        ) : (
                          'Claim'
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                }
                title="Accumulated Amount"
                titleTypographyProps={{ variant: 'subtitle1' }}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  {`${formatNumber(
                    minerData.amount_accumulated
                  )} over past ${days} day(s)`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Lifetime Rounds Burned */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                avatar={<FireIcon color="primary" />}
                title="Lifetime Rounds Burned"
                titleTypographyProps={{ variant: 'subtitle1' }}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(totals?.total_burned_rounds)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Lifetime Burn */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                avatar={<FireIcon color="primary" />}
                title="Lifetime Burn"
                titleTypographyProps={{ variant: 'subtitle1' }}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(totals?.total_burned)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* 0-Trust Policy */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                avatar={<Security color="primary" />}
                title={
                  <Box display="flex" alignItems="center">
                    0-Trust Policy
                    <Tooltip
                      title={
                        <Box>
                          <Typography variant="body2" gutterBottom>
                            Ensures no default trust; requires continuous burning
                            for withdrawals.
                          </Typography>
                          <Link
                            href="https://docs.uselessbrick.com/useless-brick-docs/getting-started/0-trust-policy"
                            target="_blank"
                            rel="noopener"
                            sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
                          >
                            Learn More
                          </Link>
                        </Box>
                      }
                      arrow
                      placement="top"
                    >
                      <InfoIcon
                        color="action"
                        sx={{ ml: 0.5, cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </Box>
                }
                titleTypographyProps={{ variant: 'subtitle1' }}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  {formatNumber(minerData.second_law_requirement)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
       </Grid>
      </Paper>
      {/* Add BurnedTokensProjection component here */}
      <BurnedTokensProjection
        walletAddress={walletAddress}
        secondLawRequirement={parseFloat(minerData.second_law_requirement)}
      />
      {/* Add ClaimHistory component here */}
      <ClaimHistory walletAddress={walletAddress} />
      {/* Add WinningBlocks component here */}
      <WinningBlocks walletAddress={walletAddress} />

      {/* Add BurnedTokensHistory component here */}
      <BurnedTokensHistory walletAddress={walletAddress} />
    </>
  );
}

function WinningBlocks({ walletAddress }) {
  const [winningBlocks, setWinningBlocks] = useState([]);
  const [totalWins, setTotalWins] = useState(0);
  const [page, setPage] = useState(1);
  const perPage = 10; // Show 10 wins per page
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchWinningBlocks = async (currentPage) => {
    setIsLoading(true);
    setError('');
    try {
      const API_BASE_URL =
        process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

      const response = await fetch(
        `${API_BASE_URL}/api/miner/${encodeURIComponent(
          walletAddress
        )}/winning_blocks?page=${currentPage}&per_page=${perPage}`,
        {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setWinningBlocks(data.winning_blocks);
        setTotalWins(data.total_wins);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch winning rounds');
      }
    } catch (err) {
      setError('Error fetching winning rounds: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWinningBlocks(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 4 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Winning Rounds History
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : winningBlocks.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Round ID</TableCell>
                <TableCell>Reward</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {winningBlocks.map((block) => (
                <TableRow key={block.round_id}>
                  <TableCell>{block.round_id}</TableCell>
                  <TableCell>{formatNumber(block.reward)}</TableCell>
                  <TableCell>
                    {new Date(block.round_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {new Date(block.round_time).toLocaleTimeString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <Pagination
              count={Math.ceil(totalWins / perPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
              siblingCount={1}
              boundaryCount={1}
            />
          </Box>
        </>
      ) : (
        <Typography variant="body2" sx={{ mt: 2 }}>
          No winning rounds found.
        </Typography>
      )}
    </Paper>
  );
}

function BurnedTokensHistory({ walletAddress }) {
  const [burnedHistory, setBurnedHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const perPage = 10; // Number of entries per page
  const [totalPages, setTotalPages] = useState(1);

  const fetchBurnedHistory = async (currentPage) => {
    try {
      const API_BASE_URL =
        process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

      const response = await fetch(
        `${API_BASE_URL}/api/miner/${encodeURIComponent(
          walletAddress
        )}/burned_tokens_history?page=${currentPage}&per_page=${perPage}`,
        {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setBurnedHistory(data.burned_tokens_history);
        setTotalPages(data.pagination.total_pages);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch burned tokens history');
      }
    } catch (err) {
      setError('Error fetching burned tokens history: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (walletAddress) {
      setIsLoading(true);
      setError('');
      fetchBurnedHistory(page);
    }
  }, [walletAddress, page]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setIsLoading(true);
  };

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 4 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Token Burn History
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : burnedHistory.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Total Burned Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {burnedHistory.map((entry) => (
                <TableRow key={entry.date}>
                  <TableCell>
                    {new Date(entry.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{formatNumber(entry.total_burned)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              siblingCount={1}
              boundaryCount={1}
            />
          </Box>
        </>
      ) : (
        <Typography variant="body2" sx={{ mt: 2 }}>
          No burned tokens history found.
        </Typography>
      )}
    </Paper>
  );
}

function App() {
  const wallet = useWallet();

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <WalletMultiButton />
        </Box>
        {wallet.connected && (
          <Router>
            <div>
              {/* Wallet Connected Text */}
              <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                Wallet Connected: {shortenAddress(wallet.publicKey.toString())}
              </Typography>

              {/* Navigation Menu */}
              <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <Button
                  component={Link}
                  to="/"
                  variant="contained"
                  color="primary"
                  sx={{ mx: 1 }}
                >
                  Miner Data
                </Button>
                <Button
                  component={Link}
                  to="/airdrop"
                  variant="contained"
                  color="secondary"
                  sx={{ mx: 1 }}
                >
                  Airdrop
                </Button>
              </Box>

              {/* Define Routes */}
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route
                    path="/"
                    element={<MinerData walletAddress={wallet.publicKey.toString()} />}
                  />
                  <Route path="/airdrop" element={<Airdrop />} />
                  {/* Redirect any unknown routes to / */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Suspense>
            </div>
          </Router>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default function AppWithProviders() {
  const wallets = [new PhantomWalletAdapter()];

  return (
    <ConnectionProvider endpoint="https://devnet.helius-rpc.com/?api-key=8f481e99-5451-4b50-8e5f-87ec2b99f210">
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <App />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
