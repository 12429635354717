// src/components/Airdrop.js

import React from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

function Airdrop() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const experiments = [
    {
      id: 1,
      name: '🧪┇1-decoded',
      startDate: '11/19/2024 18:00 UTC',
      endDate: '12/06/2024 18:00 UTC',
      points: 'Calculating',
    },
    {
      id: 2,
      name: '🧪┇2-great-divide',
      startDate: '11/21/2024 18:00 UTC',
      endDate: '12/10/2024 18:00 UTC',
      points: 'Calculating',
    },
    {
      id: 3,
      name: '🧪┇3-vault-unlock',
      startDate: '11/22/2024 18:00 UTC',
      endDate: '12/14/2024 18:00 UTC',
      points: 'On-going',
    },
    {
      id: 4,
      name: '🧪┇4-building-connection',
      startDate: '11/28/2024 18:00 UTC',
      endDate: '--/--/--',
      points: 'On-going',
    },
    {
      id: 5,
      name: '💦┇brickdrip (Bonus Experiment)',
      startDate: '12/01/2024 18:00 UTC',
      endDate: '--/--/--',
      points: 'On-going',
      isBonus: true,
    },
  ];

  return (
    <Box sx={{ p: { xs: 2, sm: 4 } }}>
      {/* Description */}
      <Typography
        variant="body1"
        align="center"
        sx={{
          mb: { xs: 3, sm: 4 },
          fontSize: { xs: '0.9rem', sm: '1.1rem' },
          color: 'rgba(0, 0, 0, 0.7)', // Subtle text color
        }}
      >
        Below is the current list of experiments. Scores for each wallet are being recorded in Discord.
      </Typography>

      {/* Responsive Table or Stack */}
      {isMobile ? (
        // Stack Layout for Mobile
        <Stack spacing={2}>
          {experiments.map((experiment) => (
            <Paper key={experiment.id} sx={{ p: 2, boxShadow: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: experiment.isBonus ? '500' : '400',
                  color: experiment.isBonus ? '#1976d2' : 'inherit', // Purple text for bonus experiment
                  mb: 1,
                }}
              >
                {experiment.name}
              </Typography>
              <Typography variant="body2">
                <strong>Start Date:</strong> {experiment.startDate}
              </Typography>
              <Typography variant="body2">
                <strong>End Date:</strong> {experiment.endDate}
              </Typography>
              <Typography variant="body2">
                <strong>Points:</strong> {experiment.points}
              </Typography>
            </Paper>
          ))}
        </Stack>
      ) : (
        // Table Layout for Larger Screens
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: '8px',
            overflowX: 'auto', // Enable horizontal scrolling
            boxShadow: 3,
          }}
        >
          <Table size="small">
            {/* Table Header */}
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: '#000000', // Changed to a blue shade for better contrast
                }}
              >
                <TableCell
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    padding: { xs: '8px', sm: '16px' },
                  }}
                >
                  Experiment
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    padding: { xs: '8px', sm: '16px' },
                  }}
                >
                  Start Date
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    padding: { xs: '8px', sm: '16px' },
                  }}
                >
                  End Date
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    padding: { xs: '8px', sm: '16px' },
                  }}
                >
                  Experiment Points
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {experiments.map((experiment) => (
                <TableRow
                  key={experiment.id}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(98, 0, 234, 0.1)', // Light blue hover
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      fontWeight: experiment.isBonus ? '500' : '400',
                      color: experiment.isBonus ? '#000000' : 'inherit', // Blue text for bonus experiment
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      padding: { xs: '8px', sm: '16px' },
                    }}
                  >
                    {experiment.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      padding: { xs: '8px', sm: '16px' },
                    }}
                  >
                    {experiment.startDate}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      padding: { xs: '8px', sm: '16px' },
                    }}
                  >
                    {experiment.endDate}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      padding: { xs: '8px', sm: '16px' },
                    }}
                  >
                    {experiment.points}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default Airdrop;

