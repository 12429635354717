// src/components/ClaimHistory.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Pagination,
} from '@mui/material';
import { format } from 'date-fns';

/**
 * Formats a number to include commas and fixed decimal places.
 * @param {number} number - The number to format.
 * @param {number} fractionDigits - Number of decimal places.
 * @returns {string} - Formatted number as a string.
 */
function formatNumber(number, fractionDigits = 6) {
  if (number === null || number === undefined) return '-';
  return parseFloat(number).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: fractionDigits,
  });
}

/**
 * ClaimHistory Component
 * Displays a paginated table of a miner's claim history.
 * @param {string} walletAddress - The miner's wallet address.
 */
function ClaimHistory({ walletAddress }) {
  const [claimHistory, setClaimHistory] = useState([]);
  const [totalClaims, setTotalClaims] = useState(0);
  const [page, setPage] = useState(1);
  const perPage = 10; // Show 10 claims per page
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  /**
   * Fetches claim history data from the backend.
   * @param {number} currentPage - The current page number.
   */
  const fetchClaimHistory = async (currentPage) => {
    setIsLoading(true);
    setError('');
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/miner/${encodeURIComponent(
          walletAddress
        )}/claim_history?page=${currentPage}&per_page=${perPage}`,
        {
          credentials: 'include', // Include credentials if using sessions
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setClaimHistory(data.claim_history);
        setTotalClaims(data.total_claims);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch claim history.');
      }
    } catch (err) {
      setError('Error fetching claim history: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (walletAddress) {
      fetchClaimHistory(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, page]);

  /**
   * Handles page change for pagination.
   * @param {object} event - The event object.
   * @param {number} value - The new page number.
   */
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 4 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: { xs: 2, sm: 3 },
        mt: 4,
        borderRadius: 0, // Remove rounded corners
      }}
    >
      <Typography variant="h5" gutterBottom>
        Claim History
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : claimHistory.length > 0 ? (
        <>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 0, // Remove rounded corners
              overflowX: 'auto', // Enable horizontal scrolling
              boxShadow: 'none', // Remove default Paper shadow
              mt: 2,
            }}
          >
            <Table>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: 'black', // Black text
                      fontWeight: 'bold',
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      padding: { xs: '8px', sm: '16px' },
                      wordBreak: 'break-word', // Ensures long text wraps
                      backgroundColor: 'transparent', // No background color
                      borderBottom: '1px solid #ddd', // Light border line
                    }}
                  >
                    Claim ID
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      padding: { xs: '8px', sm: '16px' },
                      wordBreak: 'break-word',
                      backgroundColor: 'transparent',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    Claim Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      padding: { xs: '8px', sm: '16px' },
                      wordBreak: 'break-word',
                      backgroundColor: 'transparent',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    Fee Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      padding: { xs: '8px', sm: '16px' },
                      wordBreak: 'break-word',
                      backgroundColor: 'transparent',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    Claim Date
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {claimHistory.map((claim) => (
                  <TableRow
                    key={claim.id}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent', // Disable hover effect
                      },
                      borderBottom: '1px solid #ddd', // Light border between rows
                    }}
                  >
                    <TableCell
                      sx={{
                        color: 'black', // Black text
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        padding: { xs: '8px', sm: '16px' },
                        wordBreak: 'break-all', // Ensures long text wraps
                        borderBottom: 'none', // Remove duplicate border
                      }}
                    >
                      {claim.id}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: 'black',
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        padding: { xs: '8px', sm: '16px' },
                        wordBreak: 'break-word',
                        borderBottom: 'none',
                      }}
                    >
                      {formatNumber(claim.claim_amount)}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: 'black',
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        padding: { xs: '8px', sm: '16px' },
                        wordBreak: 'break-word',
                        borderBottom: 'none',
                      }}
                    >
                      {formatNumber(claim.fee_amount)}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: 'black',
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        padding: { xs: '8px', sm: '16px' },
                        wordBreak: 'break-word',
                        borderBottom: 'none',
                      }}
                    >
                      {format(new Date(claim.claim_date), 'PPpp')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <Pagination
              count={Math.ceil(totalClaims / perPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
              siblingCount={1}
              boundaryCount={1}
            />
          </Box>
        </>
      ) : (
        <Typography variant="body2" sx={{ mt: 2 }}>
          No claim history available.
        </Typography>
      )}
    </Paper>
  );
}

export default ClaimHistory;

